html, body {
	height: 100%;
	min-width: 320px;
}

body {
	font-size: 1.6rem; /* 10px * 1.6 = 16px */
	font-family: 'Open Sans', sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 23px;
	color: #7B7979;

	@media (max-width: 1320px) {
		font-size: 14px;
		line-height: 20px;
	}
}

section {
	position: relative;
}

.disp-none {
	display: none;
}

.hide-md {
	@media (max-width: 1100px) {
		display: none;
	}
}
.hide-xs {
	@media (max-width: 700px) {
		display: none;
	}
}

.show-sm {
	@media (max-width: 800px) {
		display: block;
	}
}