.section--articles {
	padding-top: 10px;

	.section__title {
		margin-bottom: -7px;
	}

	.col-lg-3 {
		max-width: 15.7%;
	}

	.container {
		overflow: hidden;
	}	
	.slider-nav {
		position: absolute;
		right: 0px;
		top: 13px;
		width: 69px;
	}
	.slider-nav-icon {
		top: 0px;
	
		@media (max-width: 350px) {
		}
	}
	.slider-nav--prev {
		left: 0px;
	}
	.slider-nav--next {
		right: 0px;
	}
}

.section-articles__bg {	
	position: absolute;
	background-image: url(../img/bg/articles-bg1920.png);
	background-size: contain;
	background-position: top center;
	background-repeat: no-repeat;
	width: 100%;
	height: 888px*1.5;
	top: -580px;
	left: 0;
	right: 0;
	z-index: -1;

	@media (max-width: 2400px) {
		top: -500px;
	}

	@media (max-width: 1960px) {
		top: -450px;
		height: 888px*1.2;
	}

	@media (max-width: 1920px) {
		background-size: cover;
		height: 888px;
		top: -468px;
	}
}

.articles-item {
	padding-top: 39px;
	padding-bottom: 45px;
	border-bottom: 1px solid #E5E5E5;
}

.articles-image {
	margin-bottom: 5px;
	border-radius: 27px;
}

.articles-date {
	margin-top: 9px;
	margin-bottom: 11px;
	color: #878787;
}

.articles-title {
	font-weight: 600;
	color: #373737;	
}

.articles-desc {
	margin: 0;
	color: #373737;
	width: 639px;
}

.link--articles {
	margin-top: 21px;
}
