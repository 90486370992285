header {
	position: relative;

	&::before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		background: linear-gradient(147.15deg, #FBFFED -9.53%, #FFFFFF 84.19%);
		height: 1450px;
		z-index: -2;
	}
}

.header-bg {
	background-image: url(../img/bg/header-bg1920.png);
	background-position: top right;
	background-size: cover;
	background-repeat: no-repeat;
	position: absolute;
	top: 0;
	right: 0;
	height: 1056px;
	width: 1207px;
	z-index: -1;
}

.header {
	display: flex;
	padding: 29px;
	align-items: center;
}

.logo {
	width: 178px;
	height: 106px;
	background-image: url(../img/logo.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	margin-right: 138px;	
}