.promo__section {
	padding-top: 120px;
	color: #373737;
	padding-bottom: 233px;
}

h1.promo__title {
	font-size: 68px;
	line-height: 97px;
	font-weight: 700;
	margin: 0;
	padding: 0;
}

.promo__subtitle {
	font-size: 53px;
	line-height: 76px;
	margin: 0;
	margin-top: -20px;
	margin-bottom: 35px;
}

p.promo__text {
	margin: 0;
	margin-bottom: 59px;
	width: 663px;
	color: #7B7979;
}
