.section--brands {
	padding-bottom: 225px;

	.row {
		margin-left: -30px;
		margin-right: -30px;
	}

	&::before {
		content: '';
		position: absolute;
		background-color: $equipment-bgcolor;
		top: 0;
		bottom: -100px;
		right: 0;
		left: 0;
		z-index: -1;

		@media (max-width: 800px) {
			bottom: 0;
		}
	}
}

.brands__image {
	display: block;
	margin-bottom: 34px;
	margin-left: auto;
	margin-right: auto;
}

.slider-nav {
	position: relative;
	width: 100%;
	display: none;

	@media (max-width: 800px) {
		display: block;
	}
}
.slider-nav-icon {
	top: -55px;
	width: 14px;
	height: 24px;
	position: absolute;
	z-index: 5;
	background-repeat: no-repeat;
	cursor: pointer;

	@media (max-width: 350px) {
		width: 17px;
		height: 31px;
		top: -63px;
	}
}
.slider-nav--prev {
	background-image: url(../img/brands/slider-prev.png);
	left: 11px;
}
.slider-nav--next {
	background-image: url(../img/brands/slider-next.png);
	right: 11px;
}