@media (max-width: 1600px) {

	// Header

	header::before {
		height: 1350px;
	}

	.header {
		padding: 25px;
		padding-left: 22px;
	}

	.logo {
		width: 178px;
		height: 106px;
		margin-right: 85px;	
	}
	.header-bg {
		background-image: url(../img/bg/header-bg1600.png);
		height: 1093px;
		width: 1047px;
	}

	//Promo

	.promo__section {
		padding-top: 103px;
		padding-bottom: 196px;
		padding-left: 8px;
	}
	
	.promo__subtitle {
		margin-top: -17px;
		margin-bottom: 34px;
	}
	
	p.promo__text {
		width: 52%;
	}	

	//Mklist

	.section-mklist {

		.container {
			overflow: hidden;
		}
		.row {
			padding-right: 0;
			margin-left: -55px;
			margin-right: -55px;
			justify-content: space-between;
		}
		.button {
			right: 33px;
		}
	}
	.mklist-item {
		width: 100%;
		padding-left: 40px;
		padding-right: 40px;
		justify-content: space-between;
	}
	.link--mklist {
		margin-top: 20px;
	}

	//Equipment

	.section--equipment {
		padding-bottom: 102px;
		padding-left: 18px;

		&::before {
			top: 5px;
			bottom: -10px;
			z-index: -2;
		}

		.section__title {
			margin-bottom: 66px;
		}
	}

	.section--equipment__bg {
		background-image: url(../img/bg/equipment-bg1600.png);
		top: -15px;
		height: 562px;
	}

	.equipment-item {
		width: 301px;
		padding: 18px 30px 67px;
	}	
	.equipment-item__image img {
		width: 220px;
		height: 220px;
	}
	.equipment-item__title {
		margin-top: 10px;
		margin-bottom: 20px;
	}	
	.equipment-item__list-item {
		margin-bottom: 7px;
	}	
	.link--equipment {
		margin-top: 17px;
	}	
	.equipment-item__more{
		bottom: -210px;
		left: 40px;
		padding: 30px;
		width: 275px;
	}

	//Brands

	.section--brands {
		padding-bottom: 138px;

		.container {
			overflow: hidden;
		}

		.row {
			margin-left: -20px;
			margin-right: -45px;
		}
	}	
	.brands__image {
		margin-bottom: 43px;
		height: auto;
		width: 130px;
	}

	//Artcles

	.section--articles {
		padding-top: 0;
		.section__title {
			margin-bottom: -4px;
		}

		.col-sm-8 {
			margin-left: -93px;
		}
	}	
	.section-articles__bg {
		background-image: url(../img/bg/articles-bg1600.png);
		top: -275px;
		height: 678px;
	}
	
	.articles-item {
		padding-bottom: 23px;
	}
	
	.articles-image {
		width: 94px;
		height: 94px;
	}
	
	.articles-desc {
		width: 530px;
	}
	
	.link--articles {
		margin-top: 33px;
	}

	// Footer

	footer {
		height: 524px;
		padding-top: 352px;
	}
	
	.footer__bg {
		background-image: url(../img/bg/footer-bg1600.png);
	}
	
	.footer-text {
		margin-top: 41px;
	}
}
@media (max-width: 1320px) {

	.button {
		font-size: 12px;
		line-height: 35px;
	}

	// Header

	header::before {
		height: 1125px;
	}
	.header {
		padding: 20px;
		padding-top: 42px;
	}
	.logo {
		width: 117px;
		height: 70px;
		margin-right: 94px;	
	}
	.header-bg {
		background-image: url(../img/bg/header-bg1280.png);
		height: 843px;
		width: 878px;
	}

	//Promo

	.promo__section {
		padding-top: 75px;
		padding-bottom: 160px;
	}
	
	h1.promo__title {
		font-size: 52px;
		line-height: 74px;
	}
	
	.promo__subtitle {
		font-size: 40px;
		line-height: 57px;
		margin-top: -10px;
	}
	
	p.promo__text {
		margin-bottom: 50px;
		width: 65%;
	}	

	//Mklist

	.section-mklist {

		.button {
			margin-top: 8px;
			right: 18px;
			padding-left: 37px;
			padding-right: 37px;
		}
	
		.row {
			margin-left: -15px;
			margin-right: -15px;
			padding-left: 7px;
			padding-right: 19px;
			justify-content: flex-start;
		}
	}
	.section__title {
		font-size: 40px;
		line-height: 47px;
		margin-bottom: 95px;
	}
	.mklist-item {
		padding-left: 0px;
		padding-right: 0;
		margin-bottom: 17px;
		width: 100%;
	}	
	.link {
		font-size: 12px;
	}	
	.link--mklist {
		margin-top: 13px;
		margin-left: 10px;
	}

	//Equipment

	.section--equipment {
		padding-top: 122px;
		padding-bottom: 83px;
		.container {
			padding-left: 11px;
			padding-right: 40px;
		}
		.section__title {
			margin-bottom: 52px;
		}
		&::before {
			top: -50px;
		}
	}	
	.section--equipment__bg {
		background-image: url(../img/bg/equipment-bg1280.png);
		top: -233px;
		height: 710px;
	}
	.equipment-item {
		width: 243px;
		padding: 15px 24px 50px;
	}
	.equipment-item__image img {
		width: 177px;
		height: 177px;
	}
	.equipment-item__title {
		margin-top: 5px;
		margin-bottom: 15px;
		font-size: 20px;
		line-height: 29px;
	}	
	.equipment-item__list-item {
		margin-bottom: 4px;
	}	
	.link--equipment {
		margin-top: 10px;
	}	
	.equipment-item__more{
		bottom: -180px;
		width: 250px;
	}	
	.equipment-item__more-triangle {
		top: -39px;
	}

	//Brands

	.section--brands {
		padding-bottom: 132px;
		.row {
			margin-left: -50px;
			margin-right: -40px;
		}
	}	
	.brands__image {
		margin-bottom: 37px;
		width: 111px;
	}

	//Artcles

	.section--articles {
		padding-left: 20px;
		.section__title {
			margin-bottom: 14px;
		}
		.col-sm-8 {
			margin-left: -30px;
		}
	}	
	.section-articles__bg {
		background-image: url(../img/bg/articles-bg1280.png);
		top: -220px;
		height: 566px;
	}
	
	.articles-item {
		padding-top: 36px;
		padding-bottom: 43px;
	}
	
	.articles-date {
		margin-left: -14px;
		margin-bottom: 15px;
	}

	.articles-title {
		margin-left: -14px;
		width: 335px;
	}
	
	.articles-desc {
		width: 470px;
	}
	
	.link--articles {
		margin-top: 25px;
	}

	// Footer

	footer {
		height: 443px;
		padding-top: 296px;
		padding-left: 13px;

		.button {
			padding-left: 46px;
			padding-right: 46px;
			letter-spacing: 0.13em;
		}
	}
	
	.footer__bg {
		background-image: url(../img/bg/footer-bg1280.png);
		height: 697px;
	}
	
	.footer-text {
		margin-top: 30px;
		margin-bottom: 5px;
	}
	
	.footer-link {		
		line-height: 20px;
		letter-spacing: 0.01em;
	}	
}
@media (max-width: 1100px) {
	
	// Header

	header::before {
		// height: 1125px;
	}
	.header {
		padding: 24px;
		padding-left: 15px;
	}
	.logo {
		margin-right: 44px;
	}
	.header-bg {
		background-image: url(../img/bg/header-bg960.png);
		height: 775px;
		width: 784px;
	}

	//Promo

	.promo__section {
		padding-top: 71px;
		padding-bottom: 146px;
	}	
	p.promo__text {
		margin-bottom: 36px;
		// width: 65%;
	}

	//Mklist

	.section-mklist {
		padding-left: 8px;
		.button {
			margin-top: 20px;
			right: 16px;
		}	
		.row {
			padding-left: 7px;
			padding-right: 7px;
			// margin-left: -15px;
			// margin-right: -15px;
		}
	}
	.section__title {
		width: 350px;
		margin-bottom: 46px;
	}
	.mklist-item {
		// padding-left: 0;
		// padding-right: 0;
		// margin-bottom: 17px;
		// width: 100%;
	}
	.link--mklist {
		// margin-top: 13px;
		// margin-left: 10px;
	}

	//Equipment

	.section--equipment {
		padding-top: 88px;
		padding-bottom: 53px;
		.container {
			padding-left: 15px;
			padding-right: 30px;
		}
		.section__title {
			margin-bottom: 80px;
		}
	}	
	.section--equipment__bg {
		background-image: url(../img/bg/equipment-bg960.png);
		height: 504px;
		top: -117px;
	}
	.equipment-item__title {
		margin-top: 7px;
	}
	.equipment-item__more{
		bottom: -165px;
		left: 20px;
		padding: 20px;
		width: 230px;
	}
	.equipment-item__more-triangle {
		// top: -39px;
	}

	//Brands

	.section--brands {
		padding-bottom: 123px;
		.row {
			margin-left: -55px;
			margin-right: -34px;
		}
	}	
	.brands__image {
		width: 106px;
		margin-bottom: 34px;
	}

	//Artcles

	.section--articles {
		padding-left: 15px;
		.section__title {
			// margin-bottom: 14px;
		}
		.col-sm-8 {
			margin-left: 0px;
			padding-left: 2px;
		}
		.row {
			//margin-right: 50px;
		}
		.container {
			overflow: hidden;
		}
	}	
	.section-articles__bg {
		background-image: url(../img/bg/articles-bg960.png);
		top: -197px;
		height: 534px;
	}	
	.articles-item {
		padding-top: 37px;
		padding-bottom: 37px;
	}	
	.articles-date {
		margin-left: 0px;
		margin-bottom: 13px;
	}
	.articles-title {
		margin-left: 0px;
		width: 100%;
	}	
	.articles-desc {
		width: 100%;
		padding-right: 15px;
	}	
	.link--articles {
		margin-top: 18px;
	}

	// Footer

	footer {
		height: 470px;
		padding-top: 320px;
		.button {
			// padding-left: 46px;
			// padding-right: 46px;
			// letter-spacing: 0.13em;
		}
	}	
	.footer__bg {
		background-image: url(../img/bg/footer-bg960.png);
		height: 626px;
	}	
	.footer-text {
		// margin-top: 30px;
		// margin-bottom: 5px;
	}
}
@media (max-width: 960px) {
	footer {
		height: 419px;
		padding-top: 275px;
	}	
}
@media (max-width: 800px) {

	// Header

	header::before {
		// height: 1350px;
	}
	.header {
		padding: 25px;
		padding-left: 16px;
	}
	.logo {
		width: 80px;
		height: 50px;
		margin-right: 0px;	
	}
	.header-bg {
		background-image: url(../img/bg/header-bg640.png);
		height: 720px;
		width: 640px;
	}

	//Promo

	.promo__section {
		padding-top: 20px;
		padding-bottom: 168px;
	}
	
	h1.promo__title {
		line-height: 53px;
	}
	
	.promo__subtitle {
		margin-top: 15px;
		margin-bottom: 35px;
	}
	
	p.promo__text {
		margin-bottom: 28px;
		width: 492px;
	}	

	//Mklist

	.section-mklist {
		.row {
			padding-right: 9px;
			padding-left: 5px;
			margin-left: -5px;
			margin-right: -5px;
		}
		.button {
			position: relative;
			right: auto;
			margin-top: 0;
			margin-bottom: 43px;
		}
		.col-xs-6 {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.section__title {
		width: 100%;
		margin-bottom: 25px;
	}	
	.mklist__map {		
		// padding-right: 0px;		
		&--active {
			// height: 500px;
			// margin-bottom: 25px;
		}
	}

	//Equipment

	.section--equipment {
		padding-top: 94px;
		padding-left: 0;
		padding-bottom: 60px;
		.container {
			padding-left: 15px;
			padding-right: 15px;
		}
		.section__title {
			margin-left: -5px;
			margin-bottom: 60px;
		}
		.row {
			margin-left: 0;
			margin-right: 0;
		}
	}	

	.section--equipment__bg {
		background-image: url(../img/bg/equipment-bg640.png);
		height: 782px;
		top: -273px;
	}
	.equipment-item {
		position: relative;
		width: auto;
		margin-bottom: 17px;
		padding-left: 267px;
	}	
	.equipment-item__image {
		position: absolute;
		left: 30px;
	}
	.equipment-item__more{
		bottom: -165px;
		left: 300px;
		padding: 20px;
		width: 230px;
		z-index: 2;
	}

	//Brands

	.section--brands {
		padding-bottom: 146px;
		&::before {
			// bottom: -100px;
		}
		.row {
			margin-left: auto;
			margin-right: auto;
		}
	}
	.brands__slider {
		width: 90%;
		margin-left: auto;
		margin-right: auto;
	}
	.brands__slideritem {
		width: auto;
	}
	.brands__slideritem img.brands__image {
		width: 84px;
		margin-bottom: 0;
	}

	//Artcles

	.section--articles {
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 0px;	
		.section__title {
			// margin-bottom: -5px;
		}
		.row {
			margin-left: -5px;
			margin-right: -5px;
		}
	}	
	.section-articles__bg {
		background-image: url(../img/bg/articles-bg640.png);
		height: 492px;
		top: -185px;
	}
	
	.articles-item {
		// padding-top: 37px;
		padding-bottom: 0px;
		border-bottom: none;

		img.articles-image {
			width: auto;
			margin-left: 10px;
			margin-bottom: 12px;
		}
	}
	
	.articles-date {
		margin-left: -26px;
		// margin-top: 9px;
		// margin-bottom: 11px;
	}
	.articles-title {
		margin-left: -26px;	
	}
	
	.articles-desc {
		padding-left: 5px;
		// width: 639px;
	}

	#articles {
		margin-top: 50px;
	}
	
	.link--articles {
		margin-top: 15px;
		padding-left: 5px;
	}

	// Footer

	footer {
		height: 470px;
		padding-top: 300px;
	}
	
	.footer__bg {
		background-image: url(../img/bg/footer-bg640.png);
		height: 561px;
	}
	
	.footer-text {
		// margin-top: 30px;
	}
	
	.footer-link {
		// line-height: 43px;
		// letter-spacing: 0.04em;
	}	
}
@media (max-width: 700px) {
	footer {
		height: 400px;
		padding-top: 230px;
	}
	.section-mklist .col-xs-6 {
		width: 100%;
	}
	.mklist-item {
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
	}
}
@media (max-width: 650px) {
	footer {
		height: 372px;
		padding-top: 217px;
	}
}
@media (max-width: 600px) {
	p.promo__text {
		width: 485px;
	}
	.equipment-item {
		padding-left: 200px;
	}
	.equipment-item__image {
		left: 15px;

		img {
			border-radius: 30px;
		}
	}
	.equipment-item__more{
		bottom: -165px;
		left: 230px;
		padding: 20px;
		width: 230px;
		z-index: 2;
	}
	.section--articles {
		.row {
			margin-left: 0;
			margin-right: 0;
		}
	}
	.articles-date, .articles-title {
		margin-left: 0;
		width: auto;
	}
}
@media (max-width: 500px) {
	.header-bg {
		background-image: url(../img/bg/header-bg320.png);
		height: 677px;
		width: 320px;
	}
	h1.promo__title {
		font-size: 28px;
		line-height: 29px;
	}
	.promo__subtitle {
		font-size: 21px;
		line-height: 30px;
	}
	p.promo__text {
		width: 100%;
	}
	.section__title{
		font-size: 27px;
		line-height: 32px;
	}
	.equipment-item__more{
		bottom: -165px;
		left: 150px;
		padding: 20px;
		width: 230px;
		z-index: 2;
	}
	.equipment-item__more-triangle {
		left: 100px;
	}
	.section--articles {
		.col-sm-8 {
			width: 100%;
		}
	}
	.articles-item {
		padding-top: 20px;
	}
	.articles-item img.articles-image {
		width: 84px;
		height: 84px;
		margin-left: 0;
		margin-bottom: 11px;
	}
	.articles-date {
		margin-bottom: 7px;
	}
	.articles-title {
		margin-bottom: 11px;
	}
	.link--articles {
		margin-top: 11px;
	}
	#articles {
    margin-top: 29px;
	}
}
@media (max-width: 450px) {

	.button {
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 7px;
	}
	// Header

	.header {
		padding-left: 25px;
		padding-top: 18px;
	}
	.logo {
		margin-left: auto;
		margin-right: auto;
	}

	//Promo

	.promo__section {
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 65px;
		padding-bottom: 74px;
	}
	
	.promo__subtitle {
		// font-size: 53px;
		// line-height: 76px;
		margin-top: 7px;
		margin-bottom: 12px;
	}
	
	p.promo__text {
		margin-bottom: 38px;
		// width: 50%;
	}	

	//Mklist

	.section-mklist {
		padding-left: 13px;
		padding-right: 13px;

		.button {
			padding: 7px;
			margin-bottom: 33px;
		}
		.section__title {
			margin-bottom: 22px;
		}
	}
	.mklist-item {
		width: 100%;
		margin-bottom: 7px;
	}
	.link--mklist {
		margin-left: 5px;
		margin-top: 9px;
	}


	.section--equipment {
    padding-top: 66px;
		padding-bottom: 44px;
		&::before {
			top: 120px;
		}
		.section__title {
			margin-bottom: 48px;
			padding-left: 8px;
		}
	}
	.section--equipment__bg {
    background-image: url(../img/bg/equipment-bg320.png);
    height: 312px;
    top: 116px;
	}
	.equipment-item {
		padding: 17px 29px 52px;
		margin-bottom: 19px;
	}
	.equipment-item__image {
		position: relative;
		left: 0;
		img {
			width: 162px;
			height: 162px;
		}
	}
	.equipment-item__title {
		margin-top: 8px;
		margin-bottom: 24px;
	}
	.equipment-item__list-item {
		margin-bottom: 2px;
	}
	.equipment-item__more {
    bottom: -160px;
    left: 50px;
    padding: 25px;
    width: auto;
	}
	.equipment-item__more-triangle {
		left: auto;
	}

	.section--brands {
		padding-bottom: 136px;
		
		&::before {
			bottom: -20px;
		}
	}

	.brands__slideritem img.brands__image {
		width: 104px;
	}

	//Artcles

	.section--articles {
		.section__title {
			margin-left: 13px;
		}
	}	
	.section-articles__bg {
		background-image: url(../img/bg/articles-bg320.png);
		height: 275px;
		top: -124px;
	}
	
	.articles-item {
		padding-left: 8px;
		padding-right: 8px;
	}
	
	.articles-item img.articles-image {
		margin-left: 5px;
	}
	.articles-desc {
		padding-left: 0;
		padding-right: 2px;
	}
	
	.link--articles {
		margin-left: 5px;
	}

	// Footer

	footer {
		height: 350px;
		padding-top: 207px;
		padding-left: 10px;
		padding-right: 10px;
	}
	
	.footer__bg {
		background-image: url(../img/bg/footer-bg320.png);
		height: 571px;
		background-position: top center;
	}
	
	.footer-text {
		// margin-top: 30px;
	}
	
	.footer-link {
		// line-height: 43px;
		// letter-spacing: 0.04em;
	}
}
@media (max-width: 415px) {
	footer {
		height: 400px;
		padding-top: 227px;
	}
}