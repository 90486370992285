.section--equipment {
	padding-top: 151px;
	padding-bottom: 92px;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: -10px;
		left: 0;
		right: 0;			
		background-color: $equipment-bgcolor;
		z-index: -1;
	}
}

.section--equipment__bg {
	background-image: url(../img/bg/equipment-bg1920.png);
	background-size: contain;
	background-position: top center;
	background-repeat: no-repeat;
	width: 100%;
	height: 673px*1.5;
	position: absolute;
	top: -50px;
	left: 0;
	right: 0;
	z-index: -1;

	@media (max-width: 1960px) {
		height: 673px*1.2;
	}

	@media (max-width: 1920px) {
		background-size: cover;
		height: 673px;
	}
}

.equipment-item {
	width: 364px;
	margin-left: auto;
	margin-right: auto;
	box-shadow: 0px 0px 39px rgba(0, 0, 0, 0.25);
	border-radius: 50px;
	padding: 35px;
	padding-top: 25px;
	padding-bottom: 85px;
	background-color: #fff;
}

.equipment-item__image {
	text-align: center;
}

.equipment-item__title {
	margin-top: 10px;
	margin-bottom: 33px;
	font-weight: 600;
	font-size: 23px;
	line-height: 33px;	
	color: #373737;
}

.equipment-item__list {
	text-align: left;
	margin: 0;
	padding: 0;
}

.equipment-item__list-item {
	list-style-type: none;
	margin: 0;
	padding: 0;
	font-weight: 300;
	color: #6C8526;
	margin-bottom: 13px;
}

.equipment-item__list-item-link {
	font-weight: 300;
	color: #6C8526;
	text-decoration: none;

	&:hover, &:focus {
		text-decoration: none;
		color: #373737;
	}
}

.link--equipment {
	margin-top: 30px;
	z-index: 5;
}

.equipment-item__more{
	position: absolute;
	bottom: -232px;
	left: 65px;
	padding: 40px;
	width: 305px;
	padding-bottom: 30px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
	background: #FFFFFF;
	border-radius: 40px;
	z-index: 1;
}

.equipment-item__more-triangle {
	width: 100px;
	height: 40px;
	position: absolute;
	top: -40px;
	overflow: hidden;
}
.equipment-item__more-triangle::after {
	content: "";
	position: absolute;
	width: 50px;
	height: 50px;
	background: white;
	transform: rotate(45deg); /* Не забываем про префиксы */
	top: 19px;
	left: 19px;
	box-shadow: -1px -1px 10px -2px rgba(0,0,0,0.5);
}