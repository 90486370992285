$font-color : black;
$font-color-active : #6C8526;
$font-size : 16px;
$font-weight : bold;
$font-family : 'Gotham Pro';
$line-height: 15px;
$text-transform: uppercase;
$background-color: #D9E7B2;
$li-margin-right: 40px;
$letter-spacing: 0.05em;

.nav__toggle {
    display: none;

    @media (max-width: 767px) {
        display: block;
        text-align: right;
        font-size: 32px;
        color: $font-color;
        position: relative;
        z-index: 2;
    }

    &:hover {
        color: $font-color-active;
        text-decoration: none;
        cursor: pointer;
    }

    &--fixed {
        position: fixed;
        right: 25px;
        top: 25px;
    }
}

ul.nav-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: center;

    @media (max-width: 767px) {
        display: none;
        position: fixed;
        background-color: $background-color;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        height: 0;
        box-shadow: 0 8px 6px -6px #555;
        transition: height 0.2s;
    }

    &--open {

        @media (max-width: 767px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 400px;
            transition: height 0.2s;
        }
    }

    li {
        margin: 0;
        padding: 0;
        display: inline-block;
        margin-right: $li-margin-right;
        text-transform: $text-transform;
        font-size: $font-size;
        font-family: $font-family;
        line-height: $line-height;
        font-size: $font-size;
        font-weight: $font-weight;
        letter-spacing: $letter-spacing;

        @media (max-width: 1320px) {
					font-size: 12px;
					line-height: 11px;
					margin-right: 30px;
					margin-bottom: 20px;
        }

        @media (max-width: 1100px) {
            margin-bottom: 22px;
        }

        @media (max-width: 767px) {
            display: block;
            font-size: 21px;
            margin: 0;
            margin-bottom: $li-margin-right;
        }
    }

    li:last-child {
        margin-left: 15px;
        margin-right: 0;

        @media (max-width: 1199px) {
            margin-left: 0px;
        }
    }

    a {
    color: $font-color;
    text-decoration: none;
    text-transform: $text-transform;


    &:hover, &:focus, &:active {
        color: $font-color-active;
        text-decoration: none;
    }
    }
}
    