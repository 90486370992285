@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,600,700&display=swap');

@import "../fonts/Gotham-Pro/stylesheet.css";
@import "../fonts/Geometria/stylesheet.css";

.text-grey {
	color: #C4C4C4;
}

.text-bold {
	font-weight: 600;
}

.text-small {
	font-size: 14px;
	line-height: 20px;
}

.text-big {
	font-size: 40px;
	line-height: 47px;
}

.text-middle {
	font-size: 27px;
	line-height: 32px;
}