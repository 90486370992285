
/*
 * Box-sizing-model
 */

 *,
 *:before,
 *:after {
   -moz-box-sizing: border-box;
   -webkit-box-sizing: border-box;
   box-sizing: border-box;
 }
 
 
 /*
  * Responsive images
 */
 
 img {
   max-width: 100%;
   height: auto;
 }
 
 /*
  * Cursor change on hover on Buttons
 */
 
 input[type="submit"] {
   cursor: pointer;
 }
 
 /*
  * A better looking default horizontal rule
  */
 
 hr {
     display: block;
     height: 1px;
     border: 0;
     border-top: 1px solid #ccc;
     margin: 1em 0;
     padding: 0;
 }
 
 /*
  * Allow only vertical resizing of textareas.
  */
 
 textarea {
     resize: vertical;
 }
 
 
 /*
  * Clearfix: contain floats
  *
  * For modern browsers
  * 1. The space content is one way to avoid an Opera bug when the
  *    `contenteditable` attribute is included anywhere else in the document.
  *    Otherwise it causes space to appear at the top and bottom of elements
  *    that receive the `clearfix` class.
  * 2. The use of `table` rather than `block` is only necessary if using
  *    `:before` to contain the top-margins of child elements.
  */
 
 .clearfix:before,
 .clearfix:after {
     content: " "; /* 1 */
     display: table; /* 2 */
 }
 
 .clearfix:after {
     clear: both;
 }
 
 /* ==========================================================================
    Browser Upgrade Prompt
    ========================================================================== */
 
 .browserupgrade {
     margin: 0.2em 0;
     background: #ccc;
     color: #000;
     padding: 0.2em 0;
 }
 
 