$button_border-radius: 55px;
$button_color: #FFFFFF;
$button_background--green: #6C8526;
$button_background--green-active: #373737;
$button_background--grey: #BEA89E;
$button_background--grey-active: #6C8526;
$button_padding-vertical: 7px;
$button_padding-gorisontal: 50px;
$button_text-transform: uppercase;
$button_line-height: 35px;
$button_box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.41);


.button {
	border-radius: $button_border-radius;
	padding: $button_padding-vertical $button_padding-gorisontal;
	line-height: $button_line-height;
	width: fit-content;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.11em;
	text-transform: uppercase;
	color: $button_color;
	box-shadow: $button_box-shadow;
	font-weight: 600;
	cursor: pointer;
	text-decoration: none;

	@media (max-width: 1320px) {
		padding-left: 42px;
		padding-right: 42px;
	}

	&:hover, &:focus {
		text-decoration: none;
		color: $button_color;		
	}
}

.button--green {
	background: $button_background--green;

	&:hover, &:focus {	
		background: $button_background--green-active;
	}
}

.button--grey {
	background: $button_background--grey;

	&:hover, &:focus {	
		background: $button_background--grey-active;
	}
}