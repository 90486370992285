.container {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
	position: relative;
	max-width: 1580px;

	@media (max-width: 1600px) {
		max-width: 1325px;
		padding-right: 33px;
	}

	@media (max-width: 1320px) {
		max-width: 1075px;
		padding-right: 0px;
	}

	@media (max-width: 1100px) {
		max-width: 803px;
  }
  
  @media (max-width: 800px) {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

	&::before {
		float: clear;
	}
}

.col-xxl-1,  .col-xl-1,  .col-lg-1,  .col-md-1,  .col-sm-1,  .col-xs-1,  .col-xss-1,
.col-xxl-2,  .col-xl-2,  .col-lg-2,  .col-md-2,  .col-sm-2,  .col-xs-2,  .col-xss-2,
.col-xxl-24, .col-xl-24, .col-lg-24, .col-md-24, .col-sm-24, .col-xs-24, .col-xss-24,
.col-xxl-3,  .col-xl-3,  .col-lg-3,  .col-md-3,  .col-sm-3,  .col-xs-3,  .col-xss-3,
.col-xxl-4,  .col-xl-4,  .col-lg-4,  .col-md-4,  .col-sm-4,  .col-xs-4,  .col-xss-4,
.col-xxl-5,  .col-xl-5,  .col-lg-5,  .col-md-5,  .col-sm-5,  .col-xs-5,  .col-xss-5,
.col-xxl-6,  .col-xl-6,  .col-lg-6,  .col-md-6,  .col-sm-6,  .col-xs-6,  .col-xss-6,
.col-xxl-7,  .col-xl-7,  .col-lg-7,  .col-md-7,  .col-sm-7,  .col-xs-7,  .col-xss-7,
.col-xxl-8,  .col-xl-8,  .col-lg-8,  .col-md-8,  .col-sm-8,  .col-xs-8,  .col-xss-8,
.col-xxl-9,  .col-xl-9,  .col-lg-9,  .col-md-9,  .col-sm-9,  .col-xs-9,  .col-xss-9,
.col-xxl-10, .col-xl-10, .col-lg-10, .col-md-10, .col-sm-10, .col-xs-10, .col-xss-10,
.col-xxl-11, .col-xl-11, .col-lg-11, .col-md-11, .col-sm-11, .col-xs-11, .col-xss-11,
.col-xxl-12, .col-xl-12, .col-lg-12, .col-md-12, .col-sm-12, .col-xs-12, .col-xss-12 {
  position: relative;
  min-height: 1px;
  width: 100%;
}

.col-xxs-12 {width: 100%;}
.col-xxs-11 {width: 91.66666667%;}
.col-xxs-10 {width: 83.33333333%;}
.col-xxs-9  {width: 75%;}
.col-xxs-8  {width: 66.66666667%;}
.col-xxs-7  {width: 58.33333333%;}
.col-xxs-6  {width: 50%;}
.col-xxs-5  {width: 41.66666667%;}
.col-xxs-4  {width: 33.33333333%;}
.col-xxs-3  {width: 25%;}
.col-xss-24 {width: 20%;}
.col-xxs-2  {width: 16.66666667%;}
.col-xxs-1  {width: 8.33333333%;}

@media (min-width: 351px) {

  .col-xs-12 {width: 100%;}
  .col-xs-11 {width: 91.66666667%;}
  .col-xs-10 {width: 83.33333333%;}
  .col-xs-9  {width: 75%;}
  .col-xs-8  {width: 66.66666667%;}
  .col-xs-7  {width: 58.33333333%;}
  .col-xs-6  {width: 50%;}
  .col-xs-5  {width: 41.66666667%;}
  .col-xs-4  {width: 33.33333333%;}
  .col-xs-3  {width: 25%;}
	.col-xs-24 {width: 20%;}
  .col-xs-2  {width: 16.66666667%;}
  .col-xs-1  {width: 8.33333333%;}
}
@media (min-width: 801px) {

  .col-xxl-1,  .col-xl-1,  .col-lg-1,  .col-md-1,  .col-sm-1,  .col-xs-1,  .col-xss-1,
  .col-xxl-2,  .col-xl-2,  .col-lg-2,  .col-md-2,  .col-sm-2,  .col-xs-2,  .col-xss-2,
  .col-xxl-24, .col-xl-24, .col-lg-24, .col-md-24, .col-sm-24, .col-xs-24, .col-xss-24,
  .col-xxl-3,  .col-xl-3,  .col-lg-3,  .col-md-3,  .col-sm-3,  .col-xs-3,  .col-xss-3,
  .col-xxl-4,  .col-xl-4,  .col-lg-4,  .col-md-4,  .col-sm-4,  .col-xs-4,  .col-xss-4,
  .col-xxl-5,  .col-xl-5,  .col-lg-5,  .col-md-5,  .col-sm-5,  .col-xs-5,  .col-xss-5,
  .col-xxl-6,  .col-xl-6,  .col-lg-6,  .col-md-6,  .col-sm-6,  .col-xs-6,  .col-xss-6,
  .col-xxl-7,  .col-xl-7,  .col-lg-7,  .col-md-7,  .col-sm-7,  .col-xs-7,  .col-xss-7,
  .col-xxl-8,  .col-xl-8,  .col-lg-8,  .col-md-8,  .col-sm-8,  .col-xs-8,  .col-xss-8,
  .col-xxl-9,  .col-xl-9,  .col-lg-9,  .col-md-9,  .col-sm-9,  .col-xs-9,  .col-xss-9,
  .col-xxl-10, .col-xl-10, .col-lg-10, .col-md-10, .col-sm-10, .col-xs-10, .col-xss-10,
  .col-xxl-11, .col-xl-11, .col-lg-11, .col-md-11, .col-sm-11, .col-xs-11, .col-xss-11,
  .col-xxl-12, .col-xl-12, .col-lg-12, .col-md-12, .col-sm-12, .col-xs-12, .col-xss-12 {
    padding-right: 15px;
    padding-left: 15px;
  }
  .col-sm-12 {width: 100%;}
  .col-sm-11 {width: 91.66666667%;}
  .col-sm-10 {width: 83.33333333%;}
  .col-sm-9  {width: 75%;}
  .col-sm-8  {width: 66.66666667%;}
  .col-sm-7  {width: 58.33333333%;}
  .col-sm-6  {width: 50%;}
  .col-sm-5  {width: 41.66666667%;}
  .col-sm-4  {width: 33.33333333%;}
  .col-sm-3  {width: 25%;}
	.col-sm-24 {width: 20%;}
  .col-sm-2  {width: 16.66666667%;}
  .col-sm-1  {width: 8.33333333%;}
}
@media (min-width: 1101px) {
  .col-md-12 {width: 100%;}
  .col-md-11 {width: 91.66666667%;}
  .col-md-10 {width: 83.33333333%;}
  .col-md-9 {width: 75%;}
  .col-md-8 {width: 66.66666667%;}
  .col-md-7 {width: 58.33333333%;}
  .col-md-6 {width: 50%;}
  .col-md-5 {width: 41.66666667%;}
  .col-md-4 {width: 33.33333333%;}
  .col-md-3 {width: 25%;}
	.col-md-24 {width: 20%;}
  .col-md-2 {width: 16.66666667%;}
  .col-md-1 {width: 8.33333333%;}
}
@media (min-width: 1321px) {
  .col-lg-12 {width: 100%;}
  .col-lg-11 {width: 91.66666667%;}
  .col-lg-10 {width: 83.33333333%;}
  .col-lg-9 {width: 75%;}
  .col-lg-8 {width: 66.66666667%;}
  .col-lg-7 {width: 58.33333333%;}
  .col-lg-6 {width: 50%;}
  .col-lg-5 {width: 41.66666667%;}
  .col-lg-4 {width: 33.33333333%;}
	.col-lg-3 {width: 25%;}
	.col-lg-24 {width: 20%;}	
  .col-lg-2 {width: 16.66666667%;}
  .col-lg-1 {width: 8.33333333%;}
}
@media (min-width: 1601px) {
  .col-xl-12 {width: 100%;}
  .col-xl-11 {width: 91.66666667%;}
  .col-xl-10 {width: 83.33333333%;}
  .col-xl-9 {width: 75%;}
  .col-xl-8 {width: 66.66666667%;}
  .col-xl-7 {width: 58.33333333%;}
  .col-xl-6 {width: 50%;}
  .col-xl-5 {width: 41.66666667%;}
  .col-xl-4 {width: 33.33333333%;}
	.col-xl-3 {width: 25%;}
	.col-xl-24 {width: 20%;}	
  .col-xl-2 {width: 16.66666667%;}
  .col-xl-1 {width: 8.33333333%;}
}
@media (min-width: 1921px) {
  .col-xxl-12 {width: 100%;}
  .col-xxl-11 {width: 91.66666667%;}
  .col-xxl-10 {width: 83.33333333%;}
  .col-xxl-9  {width: 75%;}
  .col-xxl-8  {width: 66.66666667%;}
  .col-xxl-7  {width: 58.33333333%;}
  .col-xxl-6  {width: 50%;}
  .col-xxl-5  {width: 41.66666667%;}
  .col-xxl-4  {width: 33.33333333%;}
  .col-xxl-3  {width: 25%;}
  .col-xxl-24 {width: 20%;}
  .col-xxl-2  {width: 16.66666667%;}
  .col-xxl-1  {width: 8.33333333%;}
}