.section-mklist {
	.row {
		padding-right: 137px;
	}

	.button {
		margin-top: 15px;
		position: absolute;
		right: 15px;
		top: 0;
	}
}

.section__title {
	font-size: 50px;
	line-height: 71px;
	font-weight: 600;
	color: #373737;
	margin-bottom: 77px;
}

.mklist__map {
	z-index: 2;
	width: 100%;
	height: 0;
	padding-right: 90px;
	transition: 0.2s;

	@media (max-width: 1600px) {
		padding-left: 50px;
		padding-right: 50px;
	}
	@media (max-width: 1320px) {
		padding-left: 15px;
		padding-right: 15px;
	}
	@media (max-width: 330px) {
		padding-right: 0;
	}

	&--active {
		height: 500px;
		margin-bottom: 25px;
		
		@media (max-width: 1320px) {
			height: 400px;
		}
		@media (max-width: 1100px) {
			height: 350px;
		}
		@media (max-width: 800px) {
			height: 300px;
		}
		@media (max-width: 450px) {
			height: 250px;
		}
		@media (max-width: 330px) {
			height: 200px;
		}

	}

	iframe {
		width: 100%;
		height: 100%;
	}
}

.mklist-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 14px;
	font-weight: 300;
	width: 354px;
}

.mklist-item__name {
	color: #6C8526;	
}

.mklist-item__amount {
	color: #828282;
}

.link {
	display: block;
	font-weight: bold;
	font-size: 14px;
	line-height: 35px;
	text-transform: uppercase;
	color: #BEA89E;
	letter-spacing: 0.11em;
	cursor: pointer;
	text-decoration: none;

	&:hover, &:focus {
		text-decoration: none;
		color: $button_background--grey-active;
	}
}

.link--mklist {
	margin-top: 40px;
}