footer {
	padding-top: 450px;
	height: 650px;
	overflow: hidden;
	position: relative;

	@media (max-width: 2400px) {
		height: 600px;
		padding-top: 430px;
	}

	@media (max-width: 2100px) {
		height: 580px;
		padding-top: 400px;
	}

	@media (max-width: 1960px) {
		height: 550px;
		padding-top: 370px;
	}

	@media (max-width: 1920px) {
		height: 555px;
		padding-top: 375px;
	}
}

.footer__bg {
	background-image: url(../img/bg/footer-bg1920.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: bottom center;
	position: absolute;
	left: 0;
	right: 0;
	bottom: -50px;
	height: 796px*1.5;
	width: 100%;
	z-index: -1;

	@media (max-width: 2200px) {
		bottom: 0;
	}

	@media (max-width: 1960px) {
		height: 796px*1.2;		
	}

	@media (max-width: 1920px) {
		background-size: cover;
		height: 796px;
	}	
}

.footer-text {
	margin-top: 40px;
	margin-bottom: 15px;

	@media (max-width: 1980px) {
		margin-top: 30px;
		margin-bottom: 0px;
		padding-bottom: 0;
	}
}

.footer-link {
	line-height: 43px;
	letter-spacing: 0.04em;
	color: #6C8526;
	text-decoration: none;

	&:hover, &:focus {
		text-decoration: none;
		color: #373737;
	}
}
